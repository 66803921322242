import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import piqlogo from "src/style/images/feedsharingpiqlogo.svg";
import pepperStone from "src/style/images/feedsharingpepperstone.svg";
import reuterslogo from "src/style/images/feedsharingreuters.svg";
import reuterslogoline from "src/style/images/feedsharingreutersdownlogo.svg";
import xlogo from "src/style/images/feedsharingxlogo.svg";
import xlogoline from "src/style/images/feedsharingxlogoup.svg";
import cmelogo from "src/style/images/feedsharingcmegroup.svg";
import cmelogoline from "src/style/images/feedsharingcmegroupuplogo.svg";
import tradingview from "src/style/images/feedsharingtradingview.svg";
import reload from "src/style/images/feedsharingreloadbtn.svg";
import menu from "src/style/images/feedsharingmenuicon.svg";
import topicline from "src/style/images/feedsharingtopicline.svg";
import important from "src/style/images/feedsharingImportant.svg";
import bloomberge from "src/style/images/feed_search_bloomberg_img.png";
import schwab from "src/style/images/feed_search_schwab_img.png";
import schwabLine from "src/style/images/feed_search_schwab_line.svg";
import VideoFeedShare from "./VideoFeedShare";
import useFeedSharing from "src/Hooks/useFeedSharing";
import ReactHtmlParser from "react-html-parser";
import { decodeEntities } from "src/utils/utilFunction";
import { getFormatedDate, uppercaseFirstLetter } from "src/utils/common";
import useAppSelector from "src/Hooks/useAppselector";
import { useAuthContext } from "src/context/AuthProvider";
import { hammerstoneDummyData, newsquawkDummyData } from "./dummyData";
import { disabledAppForLoggedOutUser } from "src/constants/common";
import { getIframeUrl } from "src/constants/polymarketWidget";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { CmeGroupApp } from "src/development/Container/Web/Components/Feeds/CmeGroupApp/CmeGroupApp";



const FeedSharingModal = ({ props }: any) => {
    const [isLoading, setIsLoading] = useState(false)
    const { darkMode } = useSelector((state: any) => ({
        darkMode: state?.user?.darkMode,
    }));
    const { isSignedIn: isUserLoggedIn } = useAuthContext();
    const { sharedFeedData, deleteModal, handleDismiss, sharedData, handleAddDashboard, isVideoShare } = useFeedSharing();
    const { hasNewsQuawkSubscription, hasHammerstoneSubscription }: any = useSelector(
        (state: any) => state.premiumapps
    );
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const iframeUrl = useMemo(() => {
        if (sharedFeedData?.appSelectorTypes === "PREDICTION_MARKETS") {
            return getIframeUrl(sharedFeedData?.slug, darkMode);
        } else {
            return "";
        }
    }, [sharedFeedData, darkMode]);

    const getContent = (slug: string) => {
        switch (slug) {
            case "cmegroup-app":
                return (
                    <CmeGroupApp
                        socket={sharedFeedData?.slug}
                        inFeedSharingModal={true}
                    />
                );
            default:
                return null;
        }
    }

    return (
        <>
            <Modal
                className={`v2-logout-modal feed-sharing-modal ${darkMode ? "dark-mode" : ""}`}
                open={deleteModal}
                title={null}
                footer={null}
                closable={false}
                centered={true}
                // onCancel={handleCancel}
                width={1260}
                style={{ height: "790px", margin: "auto" }}
                mask={true}
                maskTransitionName=""
                zIndex={1100}
            >
                <div className="head-title">
                    <img src={piqlogo}>
                    </img>
                    <img src={pepperStone}>
                    </img>
                </div>
                <div className="back-container">
                    <div className="topic">
                        <span>Fast, easy and reliable</span> information aggregation - wherever you are 🌎
                    </div>
                    <div className="logos">
                        <div className="reuters">
                            <div>
                                <img src={reuterslogo}>
                                </img>
                            </div>
                            <div className="reuters-line">
                                <img src={reuterslogoline}>
                                </img>
                            </div>
                        </div>
                        {isVideoShare ? <div className="x-logo">
                            <div className="schwab-line">
                                <img src={schwabLine}>
                                </img>
                            </div>
                            <div >
                                <img src={schwab}>
                                </img>
                            </div>
                        </div> : <div className="x-logo">
                            <div className="x-line">
                                <img src={xlogoline}>
                                </img>
                            </div>
                            <div >
                                <img src={xlogo}>
                                </img>
                            </div>
                        </div>}
                        <div className="cme-logo">
                            <div className="cme-line">
                                <img src={cmelogoline}>
                                </img>
                            </div>
                            <div >
                                <img className="bloomberge_img" src={isVideoShare ? bloomberge : cmelogo}>
                                </img>
                            </div>
                        </div>
                        <div className="trading-view-logo">
                            <div >
                                <img src={tradingview}>
                                </img>
                            </div>
                        </div>

                        {isVideoShare ?
                            <VideoFeedShare sharedFeedData={sharedFeedData} /> :
                            <div className={sharedFeedData?.appSelectorTypes === "PREDICTION_MARKETS" ? "shared-feed-container-prediction" : "shared-feed-container"}>
                                <div className="topic">
                                    <div className="topic-left">
                                        <div className="topic-name">{sharedFeedData?.title}</div>
                                        <div className="reload-btn"><img src={reload}></img></div>
                                    </div>

                                    <div className="topic-right">
                                        <img src={menu}></img>
                                    </div>
                                </div>
                                <img src={topicline} className="topic-line"></img>
                                {
                                    sharedFeedData?.appSelectorTypes === "TUTORIAL_FEED" && getContent(sharedFeedData?.slug)
                                }
                                {sharedFeedData?.appSelectoyTypes !== "TUTORIAL" && sharedFeedData?.appSelectorTypes === "PREDICTION_MARKETS" ? (<div className="iframe-container">
                                    <iframe
                                        src={iframeUrl}
                                        allow="clipboard-write"
                                        onLoad={() => setIsFetching(false)}
                                        title='polymarket-market-iframe'
                                    ></iframe>
                                </div>) : <div className={((!hasNewsQuawkSubscription) &&
                                    sharedFeedData?.slug === "newsquawk") || (!isUserLoggedIn &&
                                        sharedFeedData?.slug === "newsquawk" || (!hasHammerstoneSubscription) &&
                                        sharedFeedData?.slug === "hammerstone") || (!isUserLoggedIn &&
                                            sharedFeedData?.slug === "hammerstone") ? "total-feed hidden-feed" : "total-feed"}>
                                    {(sharedData.length === 0 && ((!hasNewsQuawkSubscription) &&
                                        sharedFeedData?.slug === "newsquawk") || ((!hasHammerstoneSubscription) &&
                                            sharedFeedData?.slug === "hammerstone") || (!isUserLoggedIn &&
                                                sharedFeedData?.slug === "newsquawk-delay")) && <div className="feed-blur-premium-apps">
                                            <div className="buy-now-box">
                                                <img src={important} style={{ marginBottom: 3 }}></img>
                                                <div className="buy-now-text"><span>{(!isUserLoggedIn &&
                                                    sharedFeedData?.slug === "newsquawk-delay") ? "Sign In" : "Buy now"}</span> {(!isUserLoggedIn &&
                                                        sharedFeedData?.slug === "newsquawk-delay") ? "to add Newsquawk Free to your dashboard." : `if you want the full real time ${uppercaseFirstLetter(sharedFeedData?.slug)} feed version`}</div>
                                            </div>
                                        </div>}
                                    {sharedData?.map((item: any, i: number) => {
                                        return (
                                            <div key={i}>
                                                <div className="feed-items">
                                                    <div className="feed-date">
                                                        {getFormatedDate(item?.pubDate || item?.created_at)}
                                                    </div>
                                                    <div className="feed-title">
                                                        {(ReactHtmlParser((decodeEntities(item?.title || item?.full_text))))}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                    {(sharedData.length === 0 && ((!hasNewsQuawkSubscription) &&
                                        sharedFeedData?.slug === "newsquawk") || (!isUserLoggedIn &&
                                            sharedFeedData?.slug === "newsquawk")) &&
                                        newsquawkDummyData?.map((item: any, i: number) => {
                                            return (
                                                <div key={i}>
                                                    <div className="feed-items">
                                                        <div className="feed-date">
                                                            {getFormatedDate(item?.pubDate || item?.created_at)}
                                                        </div>
                                                        <div className="feed-title">
                                                            {(ReactHtmlParser((decodeEntities(item?.title || item?.full_text))))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {(sharedData.length === 0 && ((!hasHammerstoneSubscription) &&
                                        sharedFeedData?.slug === "hammerstone") || (!isUserLoggedIn &&
                                            sharedFeedData?.slug === "hammerstone")) &&
                                        hammerstoneDummyData?.map((item: any, i: number) => {
                                            return (
                                                <div key={i}>
                                                    <div className="feed-items">
                                                        <div className="feed-date">
                                                            {getFormatedDate(item?.pubDate)}
                                                        </div>
                                                        <div className="feed-title">
                                                            {(ReactHtmlParser((decodeEntities(item?.title))))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>}
                            </div>
                        }
                        <div className="btn-container">
                            <button className="add-btn"
                                onClick={() => handleAddDashboard(sharedFeedData?.slug, sharedFeedData?.componentType)}>
                                {(() => {
                                    const slug = sharedFeedData?.slug;

                                    if (!isUserLoggedIn) {
                                        // User is not logged in
                                        if (slug === "newsquawk-delay" || isVideoShare || disabledAppForLoggedOutUser.includes(slug)) {
                                            return "Sign In to Add";
                                        }
                                    }
                                    // User is logged in
                                    if (slug === "newsquawk" && !hasNewsQuawkSubscription) {
                                        return "Buy Now";
                                    }
                                    if (slug === "hammerstone" && !hasHammerstoneSubscription) {
                                        return "Buy Now";
                                    }
                                    return "Add to Dashboard";

                                })()}
                            </button>
                            <button className="dismiss-btn" onClick={handleDismiss}>Dismiss</button>
                        </div>
                    </div>
                </div>
            </Modal >
        </>
    );
};

export default FeedSharingModal;
