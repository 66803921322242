export const enum trackEvents {
  ARTICLE_LIMIT_SIGNUP_BTN = "ALIMIT_SU_BTN",
  INPUT_FIELD_COMPLETE = "INPUT_FIELD_COMPLETE",
  SIGNUP_WITH_EMAIL_BTN_CLICK = "S_U_WITH_EMAIL_BTN",
  SIGNUP_TC = "S_U_TC",
  SIGNUP_EMAIL_UPDATES = "S_U_EU",
  SIGNUP_SUBMIT_BTN = "S_U_BTN",
  SIGNUP_SUCCESS = "SIGNUP_SUCCESS",

  AD_CLICK = "AD_CLICK",

  HEADER_BOOKMARK_CLICK = "HDR_BK_BTN",

  BOOKMARK_ADD_BTN = "BK_AD_BTN",
  BOOKMARK_REMOVE_BTN = "BK_RM_BTN",

  CLICK_GLOBAL_SEARCH_FIELD = "HDR_SEARCH_BTN",
  GLOBAL_SEARCH_QUERY = "HDR_SEARCH_QRY",

  FEED_SEARCH_BTN = "FD_HDR_SEARCH_BTN",
  FEED_REFRESH_BTN = "FD_HDR_RLOAD_BTN",
  FEED_FILTER_BTN = "FD_HDR_FLTR_BTN",
  FEED_SETTING_BTN = "FD_HDR_ST_BTN",
  FEED_FILTER_APPLY = "FD_ST_APLY_FLTR",
  FEED_SCROLL = "FD_SCRL",
  FEED_SESSION_TIME = "FD_SES_TIME",
  FEED_CHANGE = "FD_ST_CNG_FEED",
  FEED_SETTING_CLOSE = "FEED_ST_CLS",

  REGISTER_DRAWER_OPEN = "R_D_O",
  SIGN_IN_DRAWER_OPEN = "S_I_D_O",
  RESET_PASSWORD_DRAWER_OPEN = "R_P_D_O",
  REGISTER_DRAWER_CLOSED = "R_D_C",
  RESET_PASSWORD_DRAWER_CLOSED = "R_P_D_C",
  SIGN_IN_DRAWER_CLOSED = "S_I_D_C",

  ACTIVE_SESSION = "ACTIVE_SESSION",

  LOGIN_FAIL = "LGN_FAIL",
  LOGIN_SUCCESS = "LGN_SUCC",

  SIGN_IN_SUBMIT = "S_I_SUBMIT",

  FEED_ADD = "APS_FD_AD",
  FEED_REMOVE = "APS_FD_RM",

  FEED_HAS_KEYWORD = "FD_ST_HAS_KYWRD",

  // app selector
  APP_SELECTOR_SEARCH_ACTIVE = "AP_SEL_SRCH_ACTIVE",
  APP_SELECTOR_SEARCH = "AP_SEL_SRCH",
  APP_SELECTOR_TOOLTIP_HOVER = "AP_SEL_TLTIP",
  APP_SELECTOR_TAB_SCROLL = "AP_SEL_SCRL",
  APP_SELECTOR_TAB_CATEGORY = "AP_SEL_CAT",
  APP_SELECTOR_TAB_FILTER = "AP_SEL_FLTR",
  APP_SELECTOR_APP_SELECT = "AP_SEL_APP_SELECT",

  ARTICLE_VIEWER = "ARTCL_VIEWER",
  ARTICLE_POPUP = "ARTCL_POPUP",

  HIGH_IQ_PAYMENT_SUCCESS = "HI_IQ_SUCC",
  HIGH_IQ_PAYMENT_FAIL = "HI_IQ_FAIL",
  HIGH_IQ_SUB_TC = "HI_IQ_TC",
  HIGH_IQ_AD_PRMO_BUTTON = "HI_IQ_AD_PRMO_BTN",
  HIGH_IQ_PROMO_CODE_APPLY = "HI_IQ_PRMO_CODE_APPLY",
  GOOGLE_AUTH = "GOGL_AUTH",

  FREE_IQ_UPGRADE_RADIO_BTN = "FRE_IQ_RADIO_BTN",
  HIGH_IQ_UPGRADE_RADIO_BTN = "HI_IQ_RADIO_BTN",
  ACCOUNT_UPGRADE_MONTHLY_BTN = "ACC_UP_MON_ BTN",
  ACCOUNT_UPGRADE_YEARLY_BTN = "ACC_UP_YEA_BTN",
  UPGRADE_BTN = "HI_IQ_UPG_BTN",
  EMAIL_ME_PIQ_NEWS_BOX = "PIQ_NU_BTN",
  HI_IQ_PAY_NOW_BTN = "HI_IQ_PAY_NOW_BTN",
  HI_IQ_PROMO_CODE_WRITE = "HI_IQ_PROMO_CODE_WRITE",
  HI_IQ_DOWNGRADE_BTN = "HI_IQ_DG_BTN",

  HEADER_UPGRADE_FOR_FREE_BTN = "HDR_UPG_FREE_BTN",
  HEADER_LOGIN_BTN = "HDR_LGN_BTN",
  PRICING_PAGE_PRICE_TOGGLE = "PRICING_TOGGLE",
  CONTINUE_WITH_FREE_IQ_BTN = "CONT_FREE_IQ_BTN",
  CONTINUE_WITH_HIGH_IQ_BTN = "CONT_HIGH_IQ_BTN",
  CONTINUE_UNREGISTERED_BTN = "CONT_UNREG_BTN",
  FORGOT_PASSWORD_BTN = "FORGOT_PWD_BTN",
  SIGN_UP_CAPTCHA = "S_U_CAPTCHA",

  CONTINUE_BTN = "CONTINUE",

  SIGNUP_EMAIL_VERIFICATION_CODE = "S_U_EMAIL_VER_CODE",
  SIGNUP_EMAIL_VERIFICATION_RESEND_BTN = "S_U_EMAIL_VER_RSND_BTN",
  SIGNUP_EMAIL_VERIFICATION_CODE_SUBMIT = "S_U_EMAIL_VERIFY_BTN",
  SIGNUP_EMAIL_VERIFICATION_SUCCESS = "S_U_EMAIL_VERIFY_SUCC",
  SIGNUP_EMAIL_VERIFICATION_FAILURE = "S_U_EMAIL_VERIFY_FAIL",

  SIGNIN_EMAIL_VERIFICATION_CODE = "S_I_EMAIL_VER_CODE",
  SIGNIN_EMAIL_VERIFICATION_RESEND_BTN = "S_I_EMAIL_VER_RSND_BTN",
  SIGNIN_EMAIL_VERIFICATION_CODE_SUBMIT = "S_I_EMAIL_VERIFY_BTN",
  SIGNIN_EMAIL_VERIFICATION_SUCCESS = "S_I_EMAIL_VERIFY_SUCC",
  SIGNIN_EMAIL_VERIFICATION_FAILURE = "S_I_EMAIL_VERIFY_FAIL",

  // HIGH_IQ_CHECKOUT_PERIOD_TOGGLE = "HI_IQ_CHKOUT_TOGGLE",
  HIGH_IQ_CHECKOUT_TC = "HI_IQ_CHKOUT_TC",
  HIGH_IQ_PRMO_CODE_WRITE = "HI_IQ_PRMO_CODE_WRITE",
  HIGH_IQ_PRMO_CODE_SUCCESS = "HI_IQ_PRMO_CODE_SUCC",
  HIGH_IQ_PRMO_CODE_FAILURE = "HI_IQ_PRMO_CODE_FAIL",
  HIGH_IQ_SUBMIT = "HI_IQ_SUB",
  HIGH_IQ_CHECKOUT_PRICE_TOGGLE = "HI_IQ_CHK_PR_TGL",

  PWD_RESET_VERIFICATION_CODE = "PWD_RST_VER_CODE",
  PWD_RESET_VERIFICATION_RESEND_BTN = "PWD_RST_VER_RSND_BTN",
  PWD_RESET_VERIFICATION_CODE_SUBMIT = "PWD_RST_VERIFY_BTN",
  PWD_RESET_VERIFICATION_SUCCESS = "PWD_RST_VERIFY_SUCC",
  PWD_RESET_VERIFICATION_FAILURE = "PWD_RST_VERIFY_FAIL",

  CHANGE_PASSWORD_NEW_PWD = "CP_NEW_PWD",
  CHANGE_PASSWORD_CONFIRM_PWD = "CP_CONF_PWD",
  CHANGE_PASSWORD_SUBMIT = "CP_SUB",
  CHANGE_PASSWORD_SUCCESS = "CP_SUCC",
  CHANGE_PASSWORD_FAILURE = "CP_FAIL",

  TAKE_ME_TO_BTN = "AUTH_TAKE_ME_TO",
  LAUNCH_BTN = "LAUNCH_BTN",

  TERMS_AND_CONDITION_LINK = "TC_LINK",
  PRIVACY_POLICY_LINK = "PRI_POLY_LINK",
  DMCA_LINK = "DMCA_LINK",

  SHARE_FEED = "SHARE_FEED",

  BULK_LICENSE_UPGRADE = "BLK_LIC_UPG",
  BULK_LICENSE_UPGRADE_SUCCESS = "BLK_LIC_UPG_SUCC",
  BULK_LICENSE_UPGRADE_FAILURE = "BLK_LIC_UPG_FAIL",

  // to be done later
  // CONTAINER_CLICK = "CONT_CLK",
}
