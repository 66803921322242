"use client";

import React, { useEffect } from "react";
import { activeDrawer } from "src/interface/PiqState";
import { useTrackerContext } from "./TrackerProvider";
import { defaultColumnLayoutConfigs } from "src/SuiteConfig/columnConfiguration";
import { useSelector } from "react-redux";
import { feedAxiosInstance } from "src/store/utility";
import useFeeds from "src/Hooks/useFeeds";
import { useAuth } from "src/Hooks/useAuth";
import { useLocation } from "react-router-dom";

export const LayoutContext = React.createContext({
    oldActiveDrawerState: "",
    activeDrawerState: "",
    piqSuiteLayout: [] as any[],
    setActiveDrawer: (key: activeDrawer) => { },
    setPiqSuiteLayout: (layouts: any[]) => { },
});

export const useLayoutContext = () =>
    React.useContext(LayoutContext);

export const LayoutProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { handleDrawerOpen } = useTrackerContext()
    const { user } = useAuth()

    const [oldActiveDrawerState, setOldActiveDrawerState] =
        React.useState<activeDrawer>("");
    const [activeDrawerState, setActiveDrawerState] =
        React.useState<activeDrawer>("");
    const [piqSuiteLayout, setPiqSuiteLayout] = React.useState<any[]>([]);


    const handleActiveDrawerChange = (activeDrawer: activeDrawer) => {
        handleDrawerOpen(activeDrawer)
        setActiveDrawerState(activeDrawer)
    }
    const { Columns } = useFeeds();
    // console.log(Columns)
    const handleLayoutChange = async (newLayout: any[]) => {
        // const res = await feedAxiosInstance.get(`piq-columns/w`)
        // const Columns = res?.data?.data
        const tempLayout = await Promise.all(
            newLayout?.filter((item, index, self) =>
                index === self.findIndex((t) => t?.i === item?.i)
            )?.map(async function (item: any, key: any, list: any) {
                var colConfigs = defaultColumnLayoutConfigs.find(
                    (x: any) => x.i === item?.i
                );
                const column = Columns?.find((x: any) => x?.slug === item?.i)
                if (!colConfigs) {
                    if (column?.componentType === 'NEWS_FEED_COMPONENT' || column?.componentType === 'TWITTER_FEED_COMPONENT') {
                        colConfigs = defaultColumnLayoutConfigs.find(
                            (x: any) => x.i === "NEWS_FEED_DEFAULT"
                        );
                    } else {
                        colConfigs = defaultColumnLayoutConfigs.find(
                            (x: any) => x.i === "VIDEO_FEED_DEFAULT"
                        );
                    }
                }
                let completeIntelligence = false;
                return {
                    ...{
                        completeIntegilence: completeIntelligence,
                        i: item?.i,
                        minW: colConfigs?.minW || 1,
                        maxW: colConfigs?.maxW || 2,
                        maxH: colConfigs?.maxH || 10,
                        minH: colConfigs?.minH || 1,
                        moved: colConfigs?.moved,
                        static: colConfigs?.static,
                        isResizable: colConfigs?.isResizable,
                        isDraggable: colConfigs?.isDraggable,
                        resizeHandles: colConfigs?.resizeHandles,
                        w: item?.w || 1,
                        h: item?.h || 2,
                        x: item?.x,
                        y: item?.y,
                        type:
                            Columns?.find((x: any) => x?.slug === item?.i)?.componentType ||
                            (item?.i?.includes("tradingview") && "CHART_FEED_COMPONENT") ||
                            "VIDEO_FEED_COMPONENT",
                    },
                };
            })
        );
        setPiqSuiteLayout(tempLayout);
    };

    useEffect(() => {
        let oldState = oldActiveDrawerState;
        let newState = activeDrawerState;

        if (oldState === "" && newState === "article-list-drawer") {
            const existingDev = document.getElementById("ic_d_div_OOP_1");
            if (existingDev) {
                window.removeAds();
            }
        } else if (
            newState === "article-list-drawer" &&
            oldState === "article-details-drawer"
        ) {
            window.removeAds();
        } else if (oldState === "article-list-drawer" && newState === "") {
            window.removeAds();
            window.createNewPage();
        } else if (
            oldState === "article-details-drawer" &&
            newState === "article-list-drawer"
        ) {
            // window.removeAds();
            // window.history.pushState({}, "", "/");
        } else if (
            oldState === "article-list-drawer" &&
            newState === "article-details-drawer"
        ) {
            // window.removeElement("ic_d_div_728x90_1");
            window.removeAds();
        } else if (newState === "article-details-drawer" && oldState === "") {
            // try {
            //   window.removeAds();
            // } catch (e) {
            //   console.log("failed to remove since no ads");
            // }
            // window.createNewPage();
        }
    }, [activeDrawerState]);

    useEffect(() => {
        const urlParts = window?.location?.href?.split("/");
        const route = urlParts?.length > 3 ? urlParts[urlParts.length - 2] : null;
        const routeSlug = urlParts?.length > 4 ? urlParts[urlParts.length - 1] : null;
        const activeDrawer = sessionStorage.getItem("activeDrawer")
        if ((!user && activeDrawer !== "false")) {
            if (route && routeSlug) {
                sessionStorage.setItem("activeDrawer", "false")
            } else {
                setActiveDrawerState("app-selector-drawer")
            }
        } else {
            sessionStorage.setItem("activeDrawer", "false")
            setActiveDrawerState("")
        }
    }, [user])

    return (
        <LayoutContext.Provider
            value={{
                oldActiveDrawerState,
                activeDrawerState,
                piqSuiteLayout,
                setActiveDrawer: (key: activeDrawer) => {
                    setOldActiveDrawerState(activeDrawerState);
                    handleActiveDrawerChange(key);
                },
                setPiqSuiteLayout: (layouts: any[]) =>
                    handleLayoutChange(layouts),

            }}
        >
            {children}
        </LayoutContext.Provider>
    );
};
